/* You can add global styles to this file, and also import other style files */

body {
    font-family: Montserrat, sans-serif;
    color: #434343;
    background-size: 100% 100%;
background-position: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
    background-image: url("assets/img/background.svg");
background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }